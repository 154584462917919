<template>
  <div class="privacyPolicy">
    <div class="privacyPolicy-content">
      <h1>Дана угода є договором Оферти, у разі проставлення Ліцензіатом підтвердження з її ознайомленням і прийняттям вважається укладанням договору та прийняття всіх його умов:</h1>

      <h2>1.ТЕРМІНИ ТА ВИЗНАЧЕННЯ</h2>
      <p>1.1.Оферта - публічна пропозиція Ліцензіара, адресована будь-якій особі, укласти з ним ліцензійний договір (далі - Договір) на існуючих умовах, що містяться в Договорі.</p>
      <p>1.2.Акцепт - повне й беззастережне прийняття Ліцензіатом умов Договору.</p>
      <p>1.3.Ліцензіар - юридична особа, що уклала з Ліцензіатом Договір про надання права використання (простої (невиключної) ліцензії) Програми.</p>
      <p>1.4.Ліцензіат - юридична або фізична особа, що уклала з Ліцензіаром Договір на умовах, що містяться в оферті.</p>
      <p>1.5.Проста невиключна ліцензія - невиключне право використовувати екземпляр Програми на території України для власного споживання під позначених Ліцензіаром ім'ям, без права переробки чи іншої переробки, без права поширення.</p>
      <p>1.6.Програма - Комп’ютерна програма «АВТОМАТИЗОВАНА СИСТЕМА ОЦІНКИ КОМПЕТЕНТНОСТІ МОРЯКА».</p>
      <p>1.7.Обліковий період - період часу дорівнює не менше одного календарного місяця.</p>
      <p>1.8.Сервер - фізичний сервер для установки Програми, параметри якого попередньо узгоджені Сторонами.</p>
      <p>1.9.Система / Компоненти Системи - це будь-яке програмне забезпечення, що не є Програмою.</p>
      <p>1.10.Відправник - контактна особа з боку Ліцензіата. яка звернулася із запитом на надання технічної підтримки.</p>
      <p>1.11.АРМ - автоматизоване робоче місце.</p>
      <p>1.12.ЄДРДМ - єдиний Державний реєстр документів моряків.</p>
      <p>1.13.Категорія доступу - визначений обсяг прав доступу до функціоналу Програми, який визначає Розпорядник (Адміністратор) ЄДРДМ.</p>
      <p>1.14.Ключ доступу - ідентифікатор у вигляді облікових даних (ім’я користувача та пароля). За ініціативою Ліцензіара в залежності від категорії доступу формат ключа доступу може бути змінений, в тому числі може бути передбачено використання електронного цифрового підпису Ліцензіата записаного на захищеному носії типу «Token» та/або іншій засіб автентифікації.</p>
      <p>1.15.Абонентське обслуговування - підтримка робочого стану Програми, надання Ліцензіаром послуг з актуалізації (періодичного оновлення) інформації, яка міститься у Програмі.</p>
      <p>1.16.Розпорядник (Адміністратор) - особа відповідальна за функціонування єдиного Державного реєстру документів моряків.</p>
      <p>1.17.ЕОМ - електронно-обчислювальні машини.</p>

      <h2>2.ПРЕДМЕТ ДОГОВОРУ</h2>
      <p>2.1.Ліцензіар надає Ліцензіатові, на строк дії цього Договору, право використання (просту (невиключну) ліцензію) Комп’ютерної програми «АВТОМАТИЗОВАНА СИСТЕМА ОЦІНКИ КОМПЕТЕНТНОСТІ МОРЯКА».</p>
      <p>2.2.З метою захисту даних Ліцензіар передає Ліцензіату програмне забезпечення для шифрування даних. Зазначене програмне забезпечення (або його частина) є об’єктом авторського права третьої особи, у разі порушення якого Ліцензіат несе всі юридичні та фінансові наслідки.</p>
      <p>2.3.Право інтелектуальної власності на Програму належать третій особі.</p>
      <p>2.4.Ліцензіат має право здійснювати право на використання Програми на всій території України. Використання Програми за межами території України узгоджується Сторонами шляхом укладення Додаткових угод до цього Договору.</p>
      <p>2.5.Доступ до використання Програми надається на підставі отриманого Ліцензіатом від Ліцензіара Ключа доступу. Ключ доступу передається електронним та/або документальним шляхом. Ключ доступу не може бути переданий третім особам та є персоніфікованим для Ліцензіата. У разі втрати Ключа доступу, Ліцензіат негайно зобов'язується повідомити про це Ліцензіара для його блокування. Відповідальність за несанкціоновані дії третіх осіб щодо використання Ключа доступу та паролю, несе Ліцензіат.</p>
      <p>2.6.Цей Договір регулюється нормами законодавства України з питань інтелектуальної власності.</p>

      <h2>3.ПРАВА ТА ОБОВ’ЯЗКИ СТОРІН</h2>
      <p>3.1.Ліцензіат має право:</p>
      <p>3.1.1.здійснювати будь-які дії в межах категорії доступу, пов'язані з функціонуванням Програми, відповідно до її призначення, а саме:</p>
      <ul>
        <li>надавати працівникам організації Ліцензіата віддалений персоніфікований доступ для роботи в Програмі;</li>
        <li>надавати доступ до інформації, сформованої в результаті експлуатації Програми, з метою формування зведених звітів;</li>
        <li>використовувати Програму в діяльності, крім випадків перепродажу, оренди або передачі Програми третім особам, такі дії можливі тільки після укладення відповідних письмових угод з Ліцензіаром;</li>
      </ul>
      <p>3.2.Ліцензіат зобов'язаний:</p>
      <p>3.2.1.використовувати Програму тільки в межах тих прав і тими способами, які передбачені в цьому Договорі з урахуванням обмежень, встановлених Розпорядником (Адміністратором) ЄДРДМ;</p>
      <p>3.2.2.визначати категорії доступу до функціоналу Програми працівників Ліцензіата;</p>
      <p>3.2.3.направляти на навчання свої працівників, яким планується надати доступ до функціоналу Програми;</p>
      <p>3.2.4.негайно повідомляти Ліцензіара та Розпорядника (Адміністратора) ЄДРДМ про будь-яку зміну статусу осіб, яким надано персоніфіковане право доступу до Програми (переміщення, звільнення, тимчасова відсутність, обмеження кола функціональних обов'язків, відкриття кримінальних справ тощо);</p>
      <p>3.2.5.забезпечити надання згоди на збір та обробку персональних даних працівниками Ліцензіата. які будуть задіяні у використанні Програми, та осіб, щодо яких Розпорядником (Адміністратором) ЄДРДМ вноситься інформація до ЄДРДМ.</p>
      <p>3.3.Ліцензіат не має права:</p>
      <p>3.3.1.відтворювати Програму;</p>
      <p>3.3.2.модифікувати Програму, вилучати компоненти чи створювати похідні продукти на основі цієї Програми;</p>
      <p>3.3.3.поширювати (копіювати), здійснювати оприлюднення Програми;</p>
      <p>3.3.4.поширювати екземпляри Програми або її частин і / або здійснювати інші дії, спрямовані на витяг комерційної вигоди у відносинах з третіми особами з використання Програми;</p>
      <p>3.3.5.надавати субліцензії на будь-яке використання Програми або її частин третім особам;</p>
      <p>3.3.6.надавати доступ до Програми стороннім особам, на яких не сформовано ключу доступу;</p>
      <p>3.3.7.використовувати Програму у спосіб, не передбачений категорією доступу;</p>
      <p>3.4.Ліцензіар зобов'язаний:</p>
      <p>3.4.1.протягом 10 робочих днів з моменту виконання Ліцензіатом зобов'язань по навчанню персоналу надати Ліцензіату права на використання Програми;</p>
      <p>3.4.2.надавати право використання Програми щодня і цілодобово, за винятком часу проведення профілактичних заходів, що складають не більше 3 (трьох) діб в місяць (переважно в неробочий час);</p>
      <p>3.4.3.надавати інформацію з питань роботи з Програмою за допомогою електронної пошти;</p>
      <p>3.4.4.надавати інформацію про оновлення поточної версії Програми, які вийшли протягом терміну дії цього Договору;</p>
      <p>3.4.5.усувати можливі програмні збої в роботі Програми, що виникли з вини Ліцензіара, на підставі заявки Ліцензіата, за винятком випадків порушення Ліцензіатом Правил експлуатації Програми, викладених у змісті Програми.</p>
      <p>3.4.6.провести навчання працівників Ліцензіата та видати їм відповідне свідоцтво;</p>
      <p>3.4.7.повідомити Розпорядника (Адміністратора) ЄДРДМ про укладений Договір із Ліцензіатом.</p>
      <p>3.5.Ліцензіар має право:</p>
      <p>3.5.1.розірвати Договір і відмовити в наданні Ліцензіату прав використання Програми в разі порушення умов передбачених цим Договором та порушенням Порядку доступу до Програми.</p>
      <p>3.5.2.без попередження припиняти доступ до Програми у разі:</p>
      <ul>
        <li>підозрілого вхідного трафіку з боку ір-адрес Ліцензіата;</li>
        <li>втручання в роботу серверів Ліцензіара, в тому числі наявності у трафіку Ліцензіата ознак шкідливого ПО, перевищення кількості ріп§-запитів до серверів Ліцензіара, наявності ознак ddos-атак, тощо;</li>
        <li>наявності підозри зараження шкідливим ПО файлів Ліцензіата або Ліцензіара;</li>
        <li>передача Ключа доступу третім особам, в тому числі використання ключа на ПОЗМ з незареєстрованою ір-адресою;</li>
        <li>неповідомлення Ліцензіара про втрату ключа доступу;</li>
        <li>вчинення Ліцензіатом інших дій, які можуть вплинути на працездатність серверів Ліцензіара.</li>
      </ul>
      <p>3.5.3.випускати нові релізи і версії Програми, встановлювати умови їх надання Ліцензіату, умови технічної підтримки і супроводу;</p>
      <p>3.5.4.залучати для надання послуг за цим Договором третіх осіб.</p>

      <h2>4.ФОРС-МАЖОР</h2>
      4.1.У разі виникнення обставин непереборної сили, до яких відносяться стихійні лиха, аварії, пожежі, масові заворушення, страйки, військові дії, протиправні дії третіх осіб, вступ в силу законодавчих актів, урядових постанові і розпоряджень державних органів, прямо або побічно забороняють зазначені в цьому договір види діяльності, що перешкоджають здійсненню сторонами своїх функції за цим Договором та інших обставин, що не залежать від волевиявлення Сторін вони звільняються від <p>відповідальності за невиконання взятих на себе зобов'язань, якщо протягом десяти днів з моменту настання таких обставин і при наявності зв'язку Сторона, яка постраждала від їх впливу, доведе до відома іншої Сторони звістка про те, що трапилося, а також докладе всіх зусиль для якнайшвидшої ліквідації наслідків форс мажорних обставин.</p>
      <p>4.2.Сторона, яка зазнала збитків у зв'язку з форс-мажорними обставинами, може зажадати від сторони, що стала об'єктом дії непереборної сили, документальних підтвердження про масштаби подій, що відбулися, а також про їх вплив.</p>

      <h2>5.ВІДПОВІДАЛЬНІСТЬ СТОРІН</h2>
      <p>5.1.За невиконання або неналежне виконання зобов'язань за цим Договором Сторони несуть відповідальність відповідно до чинного законодавства України.</p>
      <p>5.2.Ліцензіат використовує надані права використання Програми на власний ризик. Ліцензіар не бере на себе відповідальність за відповідність послуг меті використання.</p>
      <p>5.3.Ліцензіат погоджується з тим, що ніяке програмне забезпечення не вільно від помилок.</p>
      <p>5.4.Ліцензіар не несе відповідальності:</p>
      <ul>
        <li>за будь-які дії Ліцензіата, пов'язані з використанням наданих прав використання Програми;</li>
        <li>за збиток будь-якого роду, понесений Ліцензіатом через втрату і/або розголошення своїх даних, необхідні для доступу до Програми;</li>
        <li>за якість сервісів (зокрема сервісів передачі даних) необхідних для роботи з Програмою, якщо їх організовують треті особи, які не залучаються Ліцензіаром.</li>
        <li>за будь-яку випадкову, непряму або послідовну шкоду (в тому числі, матеріальну, шкоду через втрату прибутку, конфіденційної або іншої інформації, припинення господарської діяльності, тощо), яка виникла через або є пов'язаною з використанням або неможливістю використання Програми, а також у випадку порушення правил використання Програми або внаслідок дій Ліцензіата, що унеможливили використання Програми.</li>
      </ul>
      <p>5.5.Ліцензіат погоджується з тим. що для роботи з Програмою Ліцензіатові необхідно використовувати програмне забезпечення (веб-браузери, операційні системи та інше) і обладнання (персональні комп'ютери, мережеве обладнання та інше) вироблене і надане третіми особами, і Ліцензіар не може нести відповідальність за якість їх роботи.</p>
      <p>5.6.Ліцензіар забезпечує базову інформаційну безпеку даних Ліцензіата в межах, що визначаються в порядку, передбаченому чинним законодавством України.</p>
      <p>5.7.Підписанням цього Договору Ліцензіат надає згоду на використання та обробку Ліцензіаром персональних та інших даних, які знаходяться в розпорядженні Ліцензіата. Ліцензіар забезпечує резервне копіювання даних і архівне зберігання даних Ліцензіата, протягом всього терміну дії Договору. Резервне копіювання даних проводиться кожен календарний день.</p>
      <p>5.8.У разі, якщо втрата даних сталася з вини Ліцензіара, він вживає всіх необхідних заходів для відновлення даних протягом 10 (десяти) робочих днів.</p>
      <p>5.9.У разі якщо втрата даних була викликана діями Ліцензіата, відновлення даних проводиться за запитом який направляється Ліцензіару. Відновлення даних проводиться тільки за умови наявності технічної можливості.</p>
      <p>5.10.Ліцензіар не надає гарантій щодо Програми у випадку порушення правил її використання, викладених в цьому Договорі та додатках до нього.</p>
      <p>5.11.За порушення права інтелектуальної власності, в тому числі невизнання цього права чи посягання на нього та/або у випадку порушення Ліцензіатом зобов’язань визначених Договором та додатками до нього, Ліцензіат повинен сплатити Ліцензіару неустойку у вигляді штрафу у розмірі 100 000,00 (сто тисяч) грн. за кожний випадок порушення.</p>

      <h2>6.ПОРЯДОК ЗДІЙСНЕННЯ АБОНЕНСЬКОГО ОБСЛУГОВУВАННЯ ТА ІНФОРМАЦІЙНО- ТЕХНОЛОГІЧНОГО СУПРОВОДУ (ІТС)</h2>
      <p>6.1.Ліцензіат має право на безкоштовне абонентське обслуговування та постійну технічну підтримку, що здійснюється Ліцензіаром в межах цього Договору.</p>
      <p>Абонентське обслуговування включає:</p>
      <ul>
        <li>надання, оновлення, верифікація ключу доступу Ліцензіату;</li>
        <li>підготовку доповнень та змін програмного коду Програми для її оновлення, у тому числі у зв’язку з усуненням виявлених недоробок (недоліків) у Програмі;</li>
        <li>консультаційне обслуговування Ліцензіата з питань функціонування Програми та її програмно-технічної підтримки.</li>
        <li>отримання нових релізів програми, конфігурацій та форм у разі внесення змін до Законодавства будуть здійснюватися у строки коли ці зміни наберуть чинності.</li>
        <li>Консультаційне обслуговування Ліцензіата з питань функціонування Програмного забезпечення та його програмно-технічної підтримки здійснюється, у разі виникнення такої необхідності.</li>
      </ul>
      <p>6.2.Для надання Ліцензіаром інших послуг з обслуговування, не передбачених цим Договором, але необхідність яких буде виявлена Ліцензіатом в ході експлуатації Програми або з технічного супроводу Ліцензіатом Програми, Сторони домовляються окремо.</p>
      <p>6.3.Абонентське обслуговування по лінії ІТС включає:</p>
      <ul>
        <li>послуги лінії консультацій Ліцензіара за телефонами:</li>
        <li>+380487954357 та електронній пошті: support@explorer.od.ua</li>
        <li>послуги лінії консультацій в чаті через сайт: http://www. explorer.od.ua</li>
      </ul>
      <p>6.4.Послуги ІТС надаються протягом строку дії ліцензійних умов, починаючи з дня укладання цього Договору. </p>
      <p>6.5.Всі звернення направляються на зазначену у Договорі електронну пошту відповідно до додатків Договору.</p>

      <h2>7.ТЕРМІН ДІЇ І ПОРЯДОК РОЗІРВАННЯ ДОГОВОРУ</h2>
      <p>7.1.Договір набуває чинності з дати його підписання і діє протягом одного року, але не більше строку дії спеціальної ліцензії Ліцензіата, при припинення строку дії ліцензії, Договір автоматично припиняє свою дію.</p>
      <p>7.2.Дія цього Договору продовжується на той самий період,, якщо жодна зі Сторін не пізніше, ніж за 30 (тридцять) календарних днів, не висловила наміру не продовжувати Договір, направивши іншій Стороні повідомлення електронною поштою або засобами поштового зв’язку.</p>
      <p>7.3.Всі суперечки і розбіжності вирішуються шляхом переговорів. У разі якщо розбіжності і суперечки не можуть бути вирішені шляхом переговорів, їх розгляд передається в Господарський суд Одеської області.</p>
      <p>7.4.У разі припинення Договору вся інформація, що розміщується на сайті, персональні дані Ліцензіата і пов'язані з нею файли фізично знищуються з носіїв Ліцензіара.</p>
      <p>7.5.Договір може бути розірваний за згодою Сторін. Повідомлення про розірвання Договору повинно буди направлено у письмовому вигляді не менш як за один місяць до приблизної дати його розірвання.</p>
      <p>7.6.Одностороннє розірвання Договору Сторонами не передбачено, за виключенням випадків, коли таке розірвання є наслідком порушення умов цього Договору Ліцензіатом.</p>
      <p>7.7.Від дати, наступною за останнім днем дії Договору, будь-яке використання Програми вважається незаконним і її використання може стати підставою для накладення юридичної відповідальності на Ліцензіата.</p>

      <h2>8.ПЕРСОНАЛЬНІ ДАНІ</h2>
      <p>8.1.Ліцензіар збирає і обробляє персональні дані Ліцензіата та його працівників (а саме: прізвище, ім'я, по батькові; адреса реєстрації, номер основного документа, що посвідчує його особу, відомості про дату' видачі зазначеного документа й орган органі, РНОКПП, поштову адресу (включаючи індекс), адреса електронної пошти контактні телефони в цілях:</p>
      <ul>
        <li>виконання умов цього Договору;</li>
        <li>дотримання вимоги ЗУ «Про персональні дані».</li>
      </ul>
      <p>8.2.Ліцензіар при здійснення передачі права на використання Програми отримує згоду від Ліцензіата на збір та обробку персональних даних про Ліцензіата та його працівників з метою виконання умов цього Договору.</p>
      <p>8.3.Ліцензіат в свою чергу дає згоду Ліцензіара на збір і обробку персональних даних.</p>
      <p>8.4.При зборі та обробці персональних даних Ліцензіат і Ліцензіар не переслідує інших цілей, окрім встановлених умовами цього Договору.</p>
      <p>8.5.Доступ до персональних даних мають особи, які безпосередньо здійснюють обробку персональних даних користувача і/або Ліцензіата щодо виставлення рахунків та технічної допомоги Ліцензіара.</p>
      <p>8.6.Ліцензіар зобов'язується: дотримуватися конфіденційності щодо персональних даних користувачів і/або Ліцензіата; не допускати спроби несанкціонованого використання персональних даних користувачів і/або Ліцензіата третіми особами; виключити доступ до персональних даних користувачів і / або Ліцензіата, осіб, які не мають безпосереднього відношення до виконання Заявок.</p>

      <h2>9.КОНФІДЕНЦІЙНІСТЬ ІНФОРМАЦІЇ</h2>
      <p>9.1.Уся надана Сторонами технічна та інша інформація, пов’язана з виконанням Договору, буде вважатися конфіденційною, якщо інше (у конкретному випадку) не буде обумовлено додатково.</p>
      <p>9.2.Конфіденційна інформація, надана однією зі Сторін іншій Стороні, не може бути передана третій стороні у будь-якому вигляді (в друкованому, рукописному, на магнітному або іншому носії) без попередньої письмової згоди Сторони, яка надала цю інформацію, за винятком випадків, передбачених законодавством України.</p>
      <p>9.3.Конфіденційна інформація при її внутрішньому використанні охороняється відповідно до внутрішніх норм і правил, прийнятих у відповідної Сторони. Сторона, яка отримала конфіденційну інформацію, вживає до неї такі ж заходи безпеки, які вона вживає при користуванні власного конфіденційною інформацією для запобігання її розголошенню, поширенню та опублікуванню. Кожна зі Сторін зобов'язана забезпечити недоступність інформації для співробітників, що не потребують її для виконання робіт за цим Договором.</p>
      <p>9.4.Конфіденційна інформація має надаватися співробітникам Сторін тільки в тій частині та в тому обсязі, які дійсно необхідні для роботи за цим Договором.</p>
      <p>9.5.Використання конфіденційної інформації не повинно шкодити інтересам Сторін та здійснюється виключно з дотриманням вимог законодавства України.</p>

      <h2>10.ІНШІ УМОВИ</h2>
      <p>10.1.Дані Програми та документація, а також всі інші документи та інформація, які надаються Ліцензіаром Ліцензіату за даним Договором, є комерційною таємницею Ліцензіара, і не можуть бути розголошені, опубліковані або будь-яким іншим чином стати надбанням третьої сторони без письмової згоди Ліцензіара. Ліцензіат зобов'язується зберігати в таємниці дані Програми та надану документацію.</p>
      <p>10.2.Усі правовідносини, що виникають з цього Договору або пов'язані із ним, у тому числі пов'язані із дійсністю, укладенням, виконанням, зміною та припиненням цього Договору, тлумаченням його умов, визначенням наслідків недійсності або порушення Договору, регламентуються цим Договором та відповідними нормами чинного законодавства України, а також застосовними до таких правовідносин звичаями ділового обороту на підставі принципів добросовісності, розумності та справедливості.</p>
      <p>10.3.Після підписання цього Договору всі попередні переговори за ним, листування, попередні договори, протоколи про наміри та будь-які інші усні або письмові домовленості Сторін з питань, що так чи інакше стосуються цього Договору, втрачають юридичну силу, але можуть братися до уваги при тлумаченні умов цього Договору.</p>
      <p>10.4.Сторони несуть повну відповідальність за правильність вказаних ними у цьому Договорі реквізитів та зобов’язуються своєчасно у письмовій формі повідомляти іншу Сторону про їх зміну, а у разі неповідомлення несуть ризик настання пов'язаних із цим несприятливих наслідків.</p>
      <p>10.5.Додаткові угоди та додатки до цього Договору є його невід'ємними частинами і мають юридичну силу у разі, якщо вони викладені у письмовій формі, підписані належним чином уповноваженими особами Сторін та скріплені печатками Сторін.</p>
      <p>10.6.Всі виправлення за текстом цього Договору мають силу та можуть братися до уваги виключно за умови, що вони у кожному окремому випадку датовані, засвідчені підписами Сторін та скріплені їх печатками.</p>
      <p>10.7.Цей Договір вважається укладеним з моменту проставлення відмітки про ознайомлення та прийняття умов Договору.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LicenseAgreement',
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'licenseAgreement', access: false })
  }
}
</script>
